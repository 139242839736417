
.GS00{
    height:  90vw;
}


.pio{
    
   
    color: red;
    position: absolute;
    top: 10%;
margin-left: 1px;
display: block;
margin-right: 0px;
border-color: 10px solid black;
width: 100%;
height: 170%;
animation: transitionIN 3s;

margin-bottom: 0px;


}


@media only screen and (max-width: 600px) {

    #T{
        position: absolute;
        left: 50%;
        top: 10%;
        color: red;
       }
       
       .midp{
        color: red;
        position: center;
        margin-top: 30%;
        width: 100%;
        height: 100%;
    margin-left: 100px;
    margin-bottom:100px ;
    margin-right: 100px;
    border-color: 2px solid red;
    animation: transitionIN 3s;

    ;
    }
}
