@import "https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css";
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@700&display=swap');

.GS0{ 
  
  background-image: none;

  
}


p{
     color: black;
 }

 .LOGIN2{
   position: absolute;
   top: 10%;
   left: 44.2%;
   color: rgb(255, 175, 2);
   transform: translate(-50% -50%);
   width: 12%;
   height: 20%;

}


.REG{
   position: absolute;
   top: 15%;
   left: 35%;
   color: rgb(255, 175, 2);
   transform: translate(-50% -50%);
   width: 30%;
   height: 50%;
   animation: transitionIN 3s;

   
}

.lodiv{
position: relative;
   width: 100%;
   overflow: hidden;
   font-size: 1.042vw;
   padding: 0.2604vw 0;
   margin: 1.5vw 0;
   border-bottom: 1px solid rgb(255, 175, 2);
   border-top: none;
   

  
}

.lodiv:hover{
   border-bottom-color: black;
}
   
.debox{
  
    width: 95%;
    height: 13rem;
    padding: 0.1vw 0;
    background-color: whitesmoke;
    margin: 1.1vw 1vw;
padding: 0.2604vw 0;
overflow: hidden;
background: none;
border-width: 2px;
border-color:  rgb(255, 175, 2);
}
.debox:hover{border-color:black;}

.lodiv i{

   width: 1.3542vw;
   float: left;
   text-align: center;
}

.lodiv input{
border:  none;
outline: none;
background: none;
color: black;
width: 80%;
font-size: 0.938vw;
float: left;
margin: 0 0.521vw;

}

.gumb{
width: 100%;
background: none;
border: 2px solid rgb(255, 175, 2);
color: black;
padding: 0.2599vw;
font-size: 0.938vw ;
cursor: pointer;
margin: 5px 0;
font-family: "Oswald";
border: 0;

}

.gumbd{

   position: absolute;
   left: 28%;
   border: 2px solid rgb(255, 175, 2);
   width: 50%;
}

.lodiv2{
   
   width: 100%;
   overflow: hidden;
   font-size: 1.042vw;
   padding: 0.2604vw 0;
   margin: 1.5vw 0;
   border-bottom: 1px solid rgb(255, 175, 2);

}

.lodiv2 input{
   border:  none;
   outline: none;
   background: none;
   color: black;
   width: 80%;
   font-size: 0.938vw;
   float: right;
   margin: 0 0.521vw;
   
   }
.gumb2{
   width: 10%;
   display: block;
   background: none;
   border: 2px solid rgb(255, 175, 2);
   color: black;
   
   padding: 0.2599vw;
   font-size: 0.938vw ;
   cursor: pointer;
   margin: 0.625vw 0;
   font-family: "Oswald";
   
   }
   


.logl{

   color: black;
}

.regl{
   color: black;
}

.REG .regform{

display: none;
}
#slikac{
   animation: transitionIN 2s;

}

@media only screen and (max-width: 414px) {

   .REG{
      position: absolute;
      top: 15%;
      left: 1%;
      color: rgb(255, 175, 2);
      transform: translate(-50% -50%);
      width: 100%;
      height: 50%;

      
   }
    

   .gumb{
      width: 100%;
      background: none;
      border: 2px solid rgb(255, 175, 2);
      color: black;
      padding: 0.2599vw;
      font-size: 0.938vw ;
      cursor: pointer;
      margin: 5px 0;
      font-family: "Oswald";
      font-size: 10px;
      border: 0;
      
      }



      
.lodiv input{
   border:  none;
   outline: none;
   background: none;
   color: black;
   width: 80%;
   font-size: 10px;
   float: left;
   margin: 0 0.521vw;
   
   }
      }

      @keyframes transitionIN {

         from{
       opacity: 0;
       transform: rotateX(-10deg);
       
         }to{
       
       opacity: 1;
       transform: rotateX(0);
       }
       
       }
       
       
       
       @keyframes transitionD {
       
         from{
       opacity: 0;
       transform: translateY(-10rem);
       
         }to{
       
       opacity: 1;
       transform: translateY(0);
       
       }
       
       }
       
       
       @keyframes transitionZ {
       
         from{
       opacity: 0;
       transform: translateX(100rem);
       
         }to{
       
       opacity: 1;
       transform: translateX(100);
       
       }
       
       }
