.PP{
display: block;
position: absolute;
left: 35%;
top: 40%;
align-items: center;
animation: transitionIN 1s;
}

@keyframes transitionIN {

    from{
  opacity: 0;
  transform: rotateX(-10deg);
  
    }to{
  
  opacity: 1;
  transform: rotateX(0);
  }
  
  }
  
  
  
  @keyframes transitionD {
  
    from{
  opacity: 0;
  transform: translateY(-10rem);
  
    }to{
  
  opacity: 1;
  transform: translateY(0);
  
  }
  
  }
  
  
  @keyframes transitionZ {
  
    from{
  opacity: 0;
  transform: translateX(100rem);
  
    }to{
  
  opacity: 1;
  transform: translateX(100);
  
  }
  
  }