  
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@500&display=swap');

*{
margin: 0;
padding: 0;
box-sizing: border-box;
--color: rgb(255, 175, 2) !important
}

body{
     color:rgb(255, 175, 2); 
     background-color: white;
     font-family:"Roboto", sans-serif;
}


a{
  text-decoration: none;
}

  .PNPSv1{

   color: red;
 }
  
 #NS{
   
    display: flex;
    padding-left: 0.5vw;
    padding-top: 0.5vw;
    font-size: 1.5vw;
    font-family:"Lobster", cursive;
    text-align: left;
    display: inline-block;
    margin:0;
    color: var(--color);
 } 
 



#pa{
  position: absolute ;
  right: 0;
  top: 20%;
  font-size: 1vw;
}
  
#SG{
 
  opacity: 1;
  display: block;
  margin-right: auto;
  margin-left: auto;
  width: 750px;
  
}
  
.izplav{
  display: flex;

  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 2.7vw;
  background-color: blue;

  -webkit-user-select:none;
  -moz-user-select:none;
  -o-user-select:none;
  -user-select:none;
  
 }



   .izcrv{ 
   position: fixed;
  bottom: 0%;  
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    height: 2.7vw;
    background-color: red;
   z-index: 10;
  }

  
  
  .link{
  color: rgb(255, 175, 2);
  margin-right: 0.5vw;
  font-size: 0.9vw;
}

 .linkovi{
   font-family: "Oswald",sans-serif;
justify-content: left;
align-items: center;
  color:rgb(255, 175, 2) ;
  margin-right: 0.1vw;
  text-align: right;
 }
 */
#lor1{
  margin-top: 10vw;
  display: inline-block;
margin-left: 1vw;
opacity: 1;
}
 
.linkovi2{

padding-left: 45vw;
}

#ytb{
position: absolute;
left: 48%;
  width: 3vw;
}

#Patreon{
  position: absolute;
  width: 2.5vw;
 

}

#fb{
  width: 2.5vw;
  position: absolute;
  left: 51.5%;
  top: 0%;
  
  
  }

  #ig{
    width: 2.5vw;
    position: absolute;
    left: 54.8%;
    top: 5%;

  }
  
  #dis{
    width: 2.5vw;
    position: absolute;
    left: 58%;
    top: 5%;
  }

.link:hover{

  color: white;
  
  
  }
/*  */

#NS:hover{
  color: white;
}


@media only screen and (max-width: 440px) {
    
  .izcrv{ 
    position: fixed;
   bottom: 0%;  
     display: inline-flex;
     justify-content: space-between;
     width: 100% !important;
     height: 1.5rem;
     background-color: red;
   }
  

}


@media only screen and (max-width: 600px) {
    
  .GS{
      background-image: url(/public/images/Logo-1.png);
    
      width: 25rem;
      height: 44rem ;
  } 


  .izplav{
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    height: 1.5rem;
    background-color: blue;

    -webkit-user-select:none;
    -moz-user-select:none;
    -o-user-select:none;
    -user-select:none;

   }


   .linkovi{
   font-family: "Oswald",sans-serif;
display: flex;
justify-content: left;
align-items: center;
  color: var(--glavnaboja) ;
  margin-right: 0.5rem;
  text-align: right;
 }

 .link{
  color: rgb(255, 175, 2);
  margin-right: 0.5rem;
  font-size: 0.7rem;

 }


 #NS{
   
  display: flex;
  padding-left: 0.5vw;
  padding-top: 0.3rem;
  font-size: 0.9rem;
  font-family:"Lobster", cursive;
  text-align: left;
  display: inline-block;
  margin:0;

  
} 

.PP{
  position: center;
  color:  red;
}
#NS2{
   
  display: flex;
  padding-left: 0.5vw;
  padding-top: 0.3rem;
  font-size: 0.9rem;
  font-family:"Lobster", cursive;
  text-align: left;
  display: inline-block;
  margin:0;
}


.izcrv{ 
  position: fixed;
 bottom: 0%;  
   display: inline-flex;
   justify-content: space-between;
   width: 100% !important;
   height: 1.5rem;
   background-color: red;
 }


 #fb,#ytb, #ig, #Patreon, #dis{
   
  width: 1.5rem;
 }


 #fb{
left: 62%;
bottom: 15%;
 }

 #dis{
  left: 70%;
  bottom: 15%;
   }

 #Patreon{

 left: 40%; 
 }
}




@media only screen and (max-width: 600px) {

.izplav{
  width: 100vw !important
}

}



@keyframes transitionIN {

  from{
opacity: 0;
transform: rotateX(-10deg);

  }to{

opacity: 1;
transform: rotateX(0);
}

}



@keyframes transitionD {

  from{
opacity: 0;
transform: translateY(-10rem);

  }to{

opacity: 1;
transform: translateY(0);

}

}


@keyframes transitionZ {

  from{
opacity: 0;
transform: translateX(100rem);

  }to{

opacity: 1;
transform: translateX(100);

}

}