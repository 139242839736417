@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@700&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  
}
.GS0{
    min-height: 90vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;    
    animation: transitionIN;

}
.block{
    background-color: whitesmoke;
    border-radius: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
margin: 10px;
    align-items: left;
height: 350px;
width: 350px;
border: 2px solid ;
border-radius: 1%;



user-drag: none; 
user-select: none;
-moz-user-select: none;
-webkit-user-drag: none;
-webkit-user-select: none;
-ms-user-select: none;

animation: transitionIN 3s;

border-color: rgb(255, 175, 2);

    
}

.block:hover{
border-color: black;
border-radius: 20%;
transition: 100ms linear ;
}


#BS{
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}
h4, h1, h2, h3{
    color: black;
    font-family: 'Oswald';
}


@keyframes transitionIN {

    from{
  opacity: 0;
  transform: rotateX(-10deg);
  
    }to{
  
  opacity: 1;
  transform: rotateX(0);
  }
  
  }
  
  
  
  @keyframes transitionD {
  
    from{
  opacity: 0;
  transform: translateY(-10rem);
  
    }to{
  
  opacity: 1;
  transform: translateY(0);
  
  }
  
  }
  
  
  @keyframes transitionZ {
  
    from{
  opacity: 0;
  transform: translateX(100rem);
  
    }to{
  
  opacity: 1;
  transform: translateX(100);
  
  }
  
  }