
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Girassol&display=swap');


h2{
    margin-left: 10.5vw;
    font-family: "Giraso";
    font-size: 1.6rem;
    animation: transitionIN 1s;
    color: rgb(228, 109, 11);


}

#gr{
    float: left;
    flex-direction: column;
    font-size: 1.6rem;
    bottom: 35%;
    width: 50%;
    color: rgb(228, 109, 11);

   
}

#O1{
    width: 70vh;
}

#O2{
    
    width: 56vh;
    position: absolute;
    bottom: 5%;
    display: inline-block;
   

}

#a1{
    position: absolute;
    left: 40%;
    
    top: 90%;
    
    
    font-family: "Lobster";
    font-size: 1rem;
}

.drugip{
background-color: red;
    display: flex;
    float: left;
}

#p1{
    position: absolute;
    float: left;
    flex-direction: column;

    top: 67%;
}

p{ 
font-family: "Oswald";
    font-size: 1.1rem;
color: black;
margin-left: 1vw;
animation: transitionIN 3s;


}

.desnon{
    position: absolute;
 left: 70%; 
top: 8%;
color: rgb(228, 109, 11);

}
 




ol{
    position: absolute;
    display: block;
    font-size: 1.1rem;
    font-family: "Oswald";
    color: black;
left: 73%;
top: 14%;
animation: transitionIN 3s;

}


.GS1{
      
        
    width: 100rem;
    background-color: black;
    height: 50rem;
    opacity: 1;
    background-attachment: fixed;
    background: url(/public/images/crnagora.png) no-repeat center center fixed ;


}

@media only screen and (max-width: 600px) {

.desnon{
    position: absolute;
    color: rgb(228, 109, 11);
  left: 0.1%;
  top: 50%;
  display: block;
  margin-top: 0;
}

#os{
    color: rgb(228, 109, 11);}

    ol{
        position: absolute;
        display: block;
        font-size: 1.1rem;
        font-family: "Oswald";
        color: black;
    left: 1%;
    top: 55%;
    
    }

 

    .GS1{
        background-image: none;

    }

    .GS{
        background-image: none;
    }
  

    #O1{
        display: flex;
        float: left;
        width: 100vw;
        height: 15rem;
        font-size: 100rem;
        font-family: "Oswald";
        color: black;

    }

    #a1{
      display: none;
    }
    
    #O2{
        width: 100vw;
        font-size: 20rem;
       margin-top: 100px;
        top: 10%;
        display: none;

    }

    #gr{
      
margin-top: 22rem;

    color: rgb(228, 109, 11);
    }


.izplav{
    width: 55vh;
}

}


@media only screen and (max-width: 600px) {



    .GS1,  #GS1{
        width: 100%;
        background-image: none !important; 

    }


#GSZ, .GSZ{
    background-color: blue;
    width: 100vw;
}




@media only screen and (max-width: 600px) {

   .GS{
       width: 100vw !important;
   }
}
}
