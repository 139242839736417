.js{

    width: 16vw;
}




#hrsr{
position: absolute;
top: 10%;
left: 18%;
animation: transitionIN 1s;

}

#pl{

    position: absolute;
    bottom: 10%;
    left: 18%;
    animation: transitionIN 2s;

}

#slova{
    position: absolute;
    bottom: 35%;
    left: 2%;
    animation: transitionIN 3s;
}

#uk{
position: absolute;
    float: right;
    top: 10%;
    right: 18%;
    animation: transitionIN 4s;

}

#ru{
position: absolute;
float: right;
bottom: 35%;
right: 2%;
animation: transitionIN 5s;




}

#bg{
position: absolute;
float: right;
bottom: 10%;
right: 18%;
animation: transitionIN 6s;


}

#cz{
    position: absolute;
float: left;
bottom: 5.45%;
left: 3.5%;
width: 14%;
animation: transitionIN 7s;

}

#be{

    position: absolute;
    float: right;
    right: 3.5%;
    top: 5.45%;
    bottom: 5.45%;
    width: 13%;
    animation: transitionIN 8s;

}

#mk{
    float:right;
    position: absolute;
    right: 3.5%;
    bottom: 5.45%;
    width: 14%;
    animation: transitionIN 9s;





}

#sl{
    position: absolute;
    float: left;
    top: 6%;
    left: 3.5%;
    width: 13%;
    animation: transitionIN 10s;


}

.js{
    outline: none;
    user-select: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    

}

.js:hover{
background-color: rgb(255, 175, 2);
cursor: pointer;
transition: ease-in 0.5s;
border-radius: 100%


}


@media only screen and (max-width: 600px) {



    .GS1,  #GS1{
        width: 100%;
        background-color: white;
    }


#GSZ, .GSZ{
    width: 100vw;
}






   .GS{
       width: 100vw !important;
       animation: transitionIN 3s;


   }


   #hrsr{
    position: unset;
    margin-left: 5rem;
    top: 10%;
    left: 50%;
    animation: transitionIN 1s;
    width: 60%;
    
    }
    
    #pl{
        position: unset;
        margin-left: 5rem;
        width: 60%;

        bottom: 10%;
        left: 18%;
        animation: transitionIN 2s;
    
    }
    
    #slova{
      
        position: unset;
        margin-left: 5rem;
        width: 60%;
        bottom: 35%;
        left: 2%;
        animation: transitionIN 3s;
    }
    
    #uk{
        position: unset;
        margin-left: 5rem;
        width: 60%;

        top: 10%;
        right: 18%;
        animation: transitionIN 4s;
    float: left;
    }
    
    #ru{
        position: unset;
        margin-left: 5rem;
        float: left;
        width: 60%;
    bottom: 35%;
    right: 2%;
    animation: transitionIN 5s;
    
    
    
    
    }
    
    .GS1,#GS, .izcrv{
        width: 100% !important;
        background-size: 100vw !important;
    }


    #bg{
    position: absolute;
    float: right;
    bottom: 10%;
    right: 18%;
    animation: transitionIN 6s;
    position: unset;
    margin-left: 5rem;
    float: left;
    width: 60%;

    
    }
    
    #cz{
        position: absolute;
    float: left;
    bottom: 5.45%;
    left: 3.5%;
    width: 14%;
    animation: transitionIN 7s;
    position: unset;
    margin-left: 5rem;
    float: left;
    width: 60%;

    }
    
    #be{
    
        position: absolute;
        float: right;
        right: 3.5%;
        top: 5.45%;
        bottom: 5.45%;
        width: 13%;
        animation: transitionIN 8s;
        position: unset;
        margin-left: 5rem;
        float: left;
        width: 60%;
    
    }
    
    #mk{
        float:right;
        position: absolute;
        right: 3.5%;
        bottom: 5.45%;
        width: 14%;
        animation: transitionIN 9s;
    
    
        position: unset;
        margin-left: 5rem;
        float: left;
        width: 60%;
    
    
    
    }
    
    #sl{
        position: absolute;
        float: left;
        top: 6%;
        left: 3.5%;
        width: 13%;
        animation: transitionIN 10s;
    
        position: unset;
        margin-left: 5rem;
        float: left;
        width: 60%;
    
    }
    



}

@media only screen and (max-width: 1600px) {

    #GS1{
        width: 100vw !important;
        animation: transitionIN 3s;
 

    }


    .GS1,#GS{
        width: 100%;
        background-size:100vw ;
    }
 }
 
 


@keyframes transitionIN {

    from{
  opacity: 0;
  transform: rotateX(-10deg);
  
    }to{
  
  opacity: 1;
  transform: rotateX(0);
  }
  
  }
  
  
  
  @keyframes transitionD {
  
    from{
  opacity: 0;
  transform: translateY(-10rem);
  
    }to{
  
  opacity: 1;
  transform: translateY(0);
  
  }
  
  }
  
  
  @keyframes transitionZ {
  
    from{
  opacity: 0;
  transform: translateX(100rem);
  
    }to{
  
  opacity: 1;
  transform: translateX(100);
  
  }
  
  }


  .GSZ,.GS1{
    width: 120rem;
    height: 51rem;
    opacity: 1;
    background-attachment: fixed;
    background: url("/images/SS.png") no-repeat center center fixed !important;
  
}