
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@700&display=swap');
.form{
display: block;
position: absolute;
left: 35%;
top: 10%;
height: 800px;
}

#slikac{
   background-color: black; 
   position: absolute;
 right:  2%;
 bottom: 25%;
 border-radius: 100%;
 width: 25%;
   float: left;

   outline: none;
   user-select: none;
   user-select: none;
   -moz-user-select: none !important; 
   -webkit-user-drag: none;
   -webkit-user-select: none;
   -ms-user-select: none;
}

.GS2{

     background: none; 
}

.privatnost{

    display: block;
    position: absolute;
    right: 69%;
    width: 30vw;
    height: 30vw;
    bottom: 30%;
    font-family: "Oswald";
    font-size: 1.1%;
    color: black;

    outline: none;
    user-select: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

@media only screen and (max-width: 600px) {


  .form{
    display: block;
    position: absolute;
    left: 0%;
    top: 10%;
    width: 100vw;
    height: 800px;

    
    outline: none;
    user-select: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    }

#slikac{
  display: none;
  background-color: black;
}

.privatnost{

  display: block;
  position: absolute;
  right: 1%;
  width: 100vw;
  height: 30vw;
  bottom: -100%;
  font-family: "Oswald";
  font-size: 1.1%;
  color: black;

  outline: none;
  user-select: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  animation: transitionIN 3s;

}
}



@media only screen and (max-width: 600px) {



  .GS1,  #GS1{
      width: 100%;
      
  }


#GSZ, .GSZ{
  background-color: blue;
  width: 100vw;
}




@media only screen and (max-width: 600px) {

 .GS{
     width: 100vw !important;
 }
}
}

@keyframes transitionIN {

  from{
opacity: 0;
transform: rotateX(-10deg);

  }to{

opacity: 1;
transform: rotateX(0);
}

}



@keyframes transitionD {

  from{
opacity: 0;
transform: translateY(-10rem);

  }to{

opacity: 1;
transform: translateY(0);

}

}


@keyframes transitionZ {

  from{
opacity: 0;
transform: translateX(100rem);

  }to{

opacity: 1;
transform: translateX(100);

}

}

.fl{
    font-size: 0.9rem;
color: black;
margin-left: 0vw;
}

label{
  color: black;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}

.mz{
  position: absolute;
  transform: translate(-50%, -50%);
top: 50%;
left: 50%;


}





form input {
  display: inline-block;
}

#form{
  display: block;
  position: absolute;
  margin-left: 10px;
  width: 70%;
  height: 100%;


  
}



  .lb{
    display: flexbox;
    font-family: cursive;
    margin-left: 1vw;
  }

  .i{

    width: 20%;
    overflow: hidden;
    font-size: 1.042vw;
    padding: 0.2604vw 0;
    margin: 1vw 0;
    border-bottom: 1px solid rgb(255, 175, 2);
    border-top: none;
 
   
 }
 
 .i:hover{
    border-bottom-color: black;
 }


 .i i{

  width: 1.3542vw;
  float: left;
  text-align: center;
}

.i input{
border:  none;
outline: none;
background: none;
color: black;
width: 80%;
font-size: 0.938vw;
float: left;
margin: 0 0.521vw;

}

.i2{

  width: 110%;
  overflow: hidden;
  font-size: 1.042vw;
  padding: 0.2604vw 0;
  margin: 1vw 0;
  border-bottom: 1px solid rgb(255, 175, 2);
  border-top: none;

 
}

.i2:hover{
  border-bottom-color: black;
}


.i2 i{

width: 1.3542vw;
float: left;
text-align: center;
}

.i2 input{
border:  none;
outline: none;
background: none;
color: black;
width: 80%;
font-size: 0.938vw;
float: left;
margin: 0 0.521vw;

}




.select{
  width: 20%;
  overflow: hidden;
  font-size: 1.042vw;
  padding: 0.2604vw 0;
  margin: 1vw 0;
  border-bottom: 1px solid rgb(255, 175, 2);
  border-top: none;

}


#s1{
position: absolute;
top: -4%;
width: 20%;
height: 88%;
left: 30%;
margin-top: 35px;
}

#s2{

  position: absolute;
  left: 60%;
  width: 40%;
  height: 75%;
  top: 3%
}

#submit{
  width: 100%;
background: none;
border: 2px solid rgb(255, 175, 2);
color: black;
padding: 0.2599vw;
font-size: 0.938vw ;
cursor: pointer;
margin: 5px 0;
font-family: "Oswald";
border: 0;
}

#submitd{
  position: absolute;
  left: 140%;
  bottom: -10%;
  border: 2px solid rgb(255, 175, 2);
  width: 20%;
}



@media only screen and (max-width: 600px) {

  #slikac{
    display: none !important;
  }
.rb, #rb{
  display: none !important;
}
  #submit{
    width: 100%;
  background: none;
  border: 2px solid rgb(255, 175, 2);
  color: black;
  padding: 0.2599vw;
  font-size: 15px ;
  cursor: pointer;
  margin: 5px 0;
  font-family: "Oswald";
  border: 0;
  }
  
  #submitd{
    margin-bottom: 50vw;
    right: 5%;
    float: right;
    font-size: 10px;
    bottom: 100%;
    border: 2px solid rgb(255, 175, 2);
    width: 20%;
  }
  

#pa{
  position: absolute;
  font-size: 10px;
  left: 0%;
}


#s1{
position: initial;
width: 100%;
}

#S0, #S1{
  position: initial;
  width: 139%;
}

#s2{
  position: initial;
  width: 100%;
}

#submit, #submitd{
  position: initial;
}




.i, .i2{

  width: 100%;
  overflow: hidden;
  font-size: 30000px;
  padding: 0.2604vw 0;
  margin: 1vw 0;
  border-bottom: 1px solid rgb(255, 175, 2);
  border-top: none;

 
}



.i:hover{
  border-bottom-color: black;
}


.i i{

width: 1.3542vw;
float: left;
text-align: center;
}

.i input{
border:  none;
outline: none;
background: none;
color: black;
width: 80%;
font-size: 3.938vw;
float: left;
margin: 0 0.521vw;

}

.i2{

width: 139%;
overflow: hidden;
font-size: 3.042vw;
padding: 0.2604vw 0;
margin: 1vw 0;
border-bottom: 1px solid rgb(255, 175, 2);
border-top: none;


}

.i2:hover{
border-bottom-color: black;
}


.i2 i{

width: 3.3542vw;
float: left;
text-align: center;
}

.i2 input{
border:  none;
outline: none;
background: none;
color: black;
width: 80%;
font-size: 3.938vw;
float: left;
margin: 0 0.521vw;

}




.select{
width: 20%;
overflow: hidden;
font-size: 1.042vw;
padding: 0.2604vw 0;
margin: 1vw 0;
border-bottom: 1px solid rgb(255, 175, 2);
border-top: none;

}

}