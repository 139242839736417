@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lobster&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@500&display=swap);
@import url(https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lobster&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Girassol&display=swap);
  
*{
margin: 0;
padding: 0;
box-sizing: border-box;
--color: rgb(255, 175, 2) !important
}

body{
     color:rgb(255, 175, 2); 
     background-color: white;
     font-family:"Roboto", sans-serif;
}


a{
  text-decoration: none;
}

  .PNPSv1{

   color: red;
 }
  
 #NS{
   
    display: flex;
    padding-left: 0.5vw;
    padding-top: 0.5vw;
    font-size: 1.5vw;
    font-family:"Lobster", cursive;
    text-align: left;
    display: inline-block;
    margin:0;
    color: var(--color);
 } 
 



#pa{
  position: absolute ;
  right: 0;
  top: 20%;
  font-size: 1vw;
}
  
#SG{
 
  opacity: 1;
  display: block;
  margin-right: auto;
  margin-left: auto;
  width: 750px;
  
}
  
.izplav{
  display: flex;

  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 2.7vw;
  background-color: blue;

  -webkit-user-select:none;
  -moz-user-select:none;
  -o-user-select:none;
  -user-select:none;
  
 }



   .izcrv{ 
   position: fixed;
  bottom: 0%;  
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    height: 2.7vw;
    background-color: red;
   z-index: 10;
  }

  
  
  .link{
  color: rgb(255, 175, 2);
  margin-right: 0.5vw;
  font-size: 0.9vw;
}

 .linkovi{
   font-family: "Oswald",sans-serif;
justify-content: left;
align-items: center;
  color:rgb(255, 175, 2) ;
  margin-right: 0.1vw;
  text-align: right;
 }
 */
#lor1{
  margin-top: 10vw;
  display: inline-block;
margin-left: 1vw;
opacity: 1;
}
 
.linkovi2{

padding-left: 45vw;
}

#ytb{
position: absolute;
left: 48%;
  width: 3vw;
}

#Patreon{
  position: absolute;
  width: 2.5vw;
 

}

#fb{
  width: 2.5vw;
  position: absolute;
  left: 51.5%;
  top: 0%;
  
  
  }

  #ig{
    width: 2.5vw;
    position: absolute;
    left: 54.8%;
    top: 5%;

  }
  
  #dis{
    width: 2.5vw;
    position: absolute;
    left: 58%;
    top: 5%;
  }

.link:hover{

  color: white;
  
  
  }
/*  */

#NS:hover{
  color: white;
}


@media only screen and (max-width: 440px) {
    
  .izcrv{ 
    position: fixed;
   bottom: 0%;  
     display: inline-flex;
     justify-content: space-between;
     width: 100% !important;
     height: 1.5rem;
     background-color: red;
   }
  

}


@media only screen and (max-width: 600px) {
    
  .GS{
      background-image: url(/public/images/Logo-1.png);
    
      width: 25rem;
      height: 44rem ;
  } 


  .izplav{
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    height: 1.5rem;
    background-color: blue;

    -webkit-user-select:none;
    -moz-user-select:none;
    -o-user-select:none;
    -user-select:none;

   }


   .linkovi{
   font-family: "Oswald",sans-serif;
display: flex;
justify-content: left;
align-items: center;
  color: var(--glavnaboja) ;
  margin-right: 0.5rem;
  text-align: right;
 }

 .link{
  color: rgb(255, 175, 2);
  margin-right: 0.5rem;
  font-size: 0.7rem;

 }


 #NS{
   
  display: flex;
  padding-left: 0.5vw;
  padding-top: 0.3rem;
  font-size: 0.9rem;
  font-family:"Lobster", cursive;
  text-align: left;
  display: inline-block;
  margin:0;

  
} 

.PP{
  position: center;
  color:  red;
}
#NS2{
   
  display: flex;
  padding-left: 0.5vw;
  padding-top: 0.3rem;
  font-size: 0.9rem;
  font-family:"Lobster", cursive;
  text-align: left;
  display: inline-block;
  margin:0;
}


.izcrv{ 
  position: fixed;
 bottom: 0%;  
   display: inline-flex;
   justify-content: space-between;
   width: 100% !important;
   height: 1.5rem;
   background-color: red;
 }


 #fb,#ytb, #ig, #Patreon, #dis{
   
  width: 1.5rem;
 }


 #fb{
left: 62%;
bottom: 15%;
 }

 #dis{
  left: 70%;
  bottom: 15%;
   }

 #Patreon{

 left: 40%; 
 }
}




@media only screen and (max-width: 600px) {

.izplav{
  width: 100vw !important
}

}



@-webkit-keyframes transitionIN {

  from{
opacity: 0;
-webkit-transform: rotateX(-10deg);
        transform: rotateX(-10deg);

  }to{

opacity: 1;
-webkit-transform: rotateX(0);
        transform: rotateX(0);
}

}



@keyframes transitionIN {

  from{
opacity: 0;
-webkit-transform: rotateX(-10deg);
        transform: rotateX(-10deg);

  }to{

opacity: 1;
-webkit-transform: rotateX(0);
        transform: rotateX(0);
}

}



@-webkit-keyframes transitionD {

  from{
opacity: 0;
-webkit-transform: translateY(-10rem);
        transform: translateY(-10rem);

  }to{

opacity: 1;
-webkit-transform: translateY(0);
        transform: translateY(0);

}

}



@keyframes transitionD {

  from{
opacity: 0;
-webkit-transform: translateY(-10rem);
        transform: translateY(-10rem);

  }to{

opacity: 1;
-webkit-transform: translateY(0);
        transform: translateY(0);

}

}


@-webkit-keyframes transitionZ {

  from{
opacity: 0;
-webkit-transform: translateX(100rem);
        transform: translateX(100rem);

  }to{

opacity: 1;
-webkit-transform: translateX(100);
        transform: translateX(100);

}

}


@keyframes transitionZ {

  from{
opacity: 0;
-webkit-transform: translateX(100rem);
        transform: translateX(100rem);

  }to{

opacity: 1;
-webkit-transform: translateX(100);
        transform: translateX(100);

}

}
.GS0{ 
  
  background-image: none;

  
}


p{
     color: black;
 }

 .LOGIN2{
   position: absolute;
   top: 10%;
   left: 44.2%;
   color: rgb(255, 175, 2);
   -webkit-transform: translate(-50% -50%);
           transform: translate(-50% -50%);
   width: 12%;
   height: 20%;

}


.REG{
   position: absolute;
   top: 15%;
   left: 35%;
   color: rgb(255, 175, 2);
   -webkit-transform: translate(-50% -50%);
           transform: translate(-50% -50%);
   width: 30%;
   height: 50%;
   -webkit-animation: transitionIN 3s;
           animation: transitionIN 3s;

   
}

.lodiv{
position: relative;
   width: 100%;
   overflow: hidden;
   font-size: 1.042vw;
   padding: 0.2604vw 0;
   margin: 1.5vw 0;
   border-bottom: 1px solid rgb(255, 175, 2);
   border-top: none;
   

  
}

.lodiv:hover{
   border-bottom-color: black;
}
   
.debox{
  
    width: 95%;
    height: 13rem;
    padding: 0.1vw 0;
    background-color: whitesmoke;
    margin: 1.1vw 1vw;
padding: 0.2604vw 0;
overflow: hidden;
background: none;
border-width: 2px;
border-color:  rgb(255, 175, 2);
}
.debox:hover{border-color:black;}

.lodiv i{

   width: 1.3542vw;
   float: left;
   text-align: center;
}

.lodiv input{
border:  none;
outline: none;
background: none;
color: black;
width: 80%;
font-size: 0.938vw;
float: left;
margin: 0 0.521vw;

}

.gumb{
width: 100%;
background: none;
border: 2px solid rgb(255, 175, 2);
color: black;
padding: 0.2599vw;
font-size: 0.938vw ;
cursor: pointer;
margin: 5px 0;
font-family: "Oswald";
border: 0;

}

.gumbd{

   position: absolute;
   left: 28%;
   border: 2px solid rgb(255, 175, 2);
   width: 50%;
}

.lodiv2{
   
   width: 100%;
   overflow: hidden;
   font-size: 1.042vw;
   padding: 0.2604vw 0;
   margin: 1.5vw 0;
   border-bottom: 1px solid rgb(255, 175, 2);

}

.lodiv2 input{
   border:  none;
   outline: none;
   background: none;
   color: black;
   width: 80%;
   font-size: 0.938vw;
   float: right;
   margin: 0 0.521vw;
   
   }
.gumb2{
   width: 10%;
   display: block;
   background: none;
   border: 2px solid rgb(255, 175, 2);
   color: black;
   
   padding: 0.2599vw;
   font-size: 0.938vw ;
   cursor: pointer;
   margin: 0.625vw 0;
   font-family: "Oswald";
   
   }
   


.logl{

   color: black;
}

.regl{
   color: black;
}

.REG .regform{

display: none;
}
#slikac{
   -webkit-animation: transitionIN 2s;
           animation: transitionIN 2s;

}

@media only screen and (max-width: 414px) {

   .REG{
      position: absolute;
      top: 15%;
      left: 1%;
      color: rgb(255, 175, 2);
      -webkit-transform: translate(-50% -50%);
              transform: translate(-50% -50%);
      width: 100%;
      height: 50%;

      
   }
    

   .gumb{
      width: 100%;
      background: none;
      border: 2px solid rgb(255, 175, 2);
      color: black;
      padding: 0.2599vw;
      font-size: 0.938vw ;
      cursor: pointer;
      margin: 5px 0;
      font-family: "Oswald";
      font-size: 10px;
      border: 0;
      
      }



      
.lodiv input{
   border:  none;
   outline: none;
   background: none;
   color: black;
   width: 80%;
   font-size: 10px;
   float: left;
   margin: 0 0.521vw;
   
   }
      }

      @-webkit-keyframes transitionIN {

         from{
       opacity: 0;
       -webkit-transform: rotateX(-10deg);
               transform: rotateX(-10deg);
       
         }to{
       
       opacity: 1;
       -webkit-transform: rotateX(0);
               transform: rotateX(0);
       }
       
       }

      @keyframes transitionIN {

         from{
       opacity: 0;
       -webkit-transform: rotateX(-10deg);
               transform: rotateX(-10deg);
       
         }to{
       
       opacity: 1;
       -webkit-transform: rotateX(0);
               transform: rotateX(0);
       }
       
       }
       
       
       
       @-webkit-keyframes transitionD {
       
         from{
       opacity: 0;
       -webkit-transform: translateY(-10rem);
               transform: translateY(-10rem);
       
         }to{
       
       opacity: 1;
       -webkit-transform: translateY(0);
               transform: translateY(0);
       
       }
       
       }
       
       
       
       @keyframes transitionD {
       
         from{
       opacity: 0;
       -webkit-transform: translateY(-10rem);
               transform: translateY(-10rem);
       
         }to{
       
       opacity: 1;
       -webkit-transform: translateY(0);
               transform: translateY(0);
       
       }
       
       }
       
       
       @-webkit-keyframes transitionZ {
       
         from{
       opacity: 0;
       -webkit-transform: translateX(100rem);
               transform: translateX(100rem);
       
         }to{
       
       opacity: 1;
       -webkit-transform: translateX(100);
               transform: translateX(100);
       
       }
       
       }
       
       
       @keyframes transitionZ {
       
         from{
       opacity: 0;
       -webkit-transform: translateX(100rem);
               transform: translateX(100rem);
       
         }to{
       
       opacity: 1;
       -webkit-transform: translateX(100);
               transform: translateX(100);
       
       }
       
       }

.js{

    width: 16vw;
}




#hrsr{
position: absolute;
top: 10%;
left: 18%;
-webkit-animation: transitionIN 1s;
        animation: transitionIN 1s;

}

#pl{

    position: absolute;
    bottom: 10%;
    left: 18%;
    -webkit-animation: transitionIN 2s;
            animation: transitionIN 2s;

}

#slova{
    position: absolute;
    bottom: 35%;
    left: 2%;
    -webkit-animation: transitionIN 3s;
            animation: transitionIN 3s;
}

#uk{
position: absolute;
    float: right;
    top: 10%;
    right: 18%;
    -webkit-animation: transitionIN 4s;
            animation: transitionIN 4s;

}

#ru{
position: absolute;
float: right;
bottom: 35%;
right: 2%;
-webkit-animation: transitionIN 5s;
        animation: transitionIN 5s;




}

#bg{
position: absolute;
float: right;
bottom: 10%;
right: 18%;
-webkit-animation: transitionIN 6s;
        animation: transitionIN 6s;


}

#cz{
    position: absolute;
float: left;
bottom: 5.45%;
left: 3.5%;
width: 14%;
-webkit-animation: transitionIN 7s;
        animation: transitionIN 7s;

}

#be{

    position: absolute;
    float: right;
    right: 3.5%;
    top: 5.45%;
    bottom: 5.45%;
    width: 13%;
    -webkit-animation: transitionIN 8s;
            animation: transitionIN 8s;

}

#mk{
    float:right;
    position: absolute;
    right: 3.5%;
    bottom: 5.45%;
    width: 14%;
    -webkit-animation: transitionIN 9s;
            animation: transitionIN 9s;





}

#sl{
    position: absolute;
    float: left;
    top: 6%;
    left: 3.5%;
    width: 13%;
    -webkit-animation: transitionIN 10s;
            animation: transitionIN 10s;


}

.js{
    outline: none;
    user-select: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    

}

.js:hover{
background-color: rgb(255, 175, 2);
cursor: pointer;
transition: ease-in 0.5s;
border-radius: 100%


}


@media only screen and (max-width: 600px) {



    .GS1,  #GS1{
        width: 100%;
        background-color: white;
    }


#GSZ, .GSZ{
    width: 100vw;
}






   .GS{
       width: 100vw !important;
       -webkit-animation: transitionIN 3s;
               animation: transitionIN 3s;


   }


   #hrsr{
    position: unset;
    margin-left: 5rem;
    top: 10%;
    left: 50%;
    -webkit-animation: transitionIN 1s;
            animation: transitionIN 1s;
    width: 60%;
    
    }
    
    #pl{
        position: unset;
        margin-left: 5rem;
        width: 60%;

        bottom: 10%;
        left: 18%;
        -webkit-animation: transitionIN 2s;
                animation: transitionIN 2s;
    
    }
    
    #slova{
      
        position: unset;
        margin-left: 5rem;
        width: 60%;
        bottom: 35%;
        left: 2%;
        -webkit-animation: transitionIN 3s;
                animation: transitionIN 3s;
    }
    
    #uk{
        position: unset;
        margin-left: 5rem;
        width: 60%;

        top: 10%;
        right: 18%;
        -webkit-animation: transitionIN 4s;
                animation: transitionIN 4s;
    float: left;
    }
    
    #ru{
        position: unset;
        margin-left: 5rem;
        float: left;
        width: 60%;
    bottom: 35%;
    right: 2%;
    -webkit-animation: transitionIN 5s;
            animation: transitionIN 5s;
    
    
    
    
    }
    
    .GS1,#GS, .izcrv{
        width: 100% !important;
        background-size: 100vw !important;
    }


    #bg{
    position: absolute;
    float: right;
    bottom: 10%;
    right: 18%;
    -webkit-animation: transitionIN 6s;
            animation: transitionIN 6s;
    position: unset;
    margin-left: 5rem;
    float: left;
    width: 60%;

    
    }
    
    #cz{
        position: absolute;
    float: left;
    bottom: 5.45%;
    left: 3.5%;
    width: 14%;
    -webkit-animation: transitionIN 7s;
            animation: transitionIN 7s;
    position: unset;
    margin-left: 5rem;
    float: left;
    width: 60%;

    }
    
    #be{
    
        position: absolute;
        float: right;
        right: 3.5%;
        top: 5.45%;
        bottom: 5.45%;
        width: 13%;
        -webkit-animation: transitionIN 8s;
                animation: transitionIN 8s;
        position: unset;
        margin-left: 5rem;
        float: left;
        width: 60%;
    
    }
    
    #mk{
        float:right;
        position: absolute;
        right: 3.5%;
        bottom: 5.45%;
        width: 14%;
        -webkit-animation: transitionIN 9s;
                animation: transitionIN 9s;
    
    
        position: unset;
        margin-left: 5rem;
        float: left;
        width: 60%;
    
    
    
    }
    
    #sl{
        position: absolute;
        float: left;
        top: 6%;
        left: 3.5%;
        width: 13%;
        -webkit-animation: transitionIN 10s;
                animation: transitionIN 10s;
    
        position: unset;
        margin-left: 5rem;
        float: left;
        width: 60%;
    
    }
    



}

@media only screen and (max-width: 1600px) {

    #GS1{
        width: 100vw !important;
        -webkit-animation: transitionIN 3s;
                animation: transitionIN 3s;
 

    }


    .GS1,#GS{
        width: 100%;
        background-size:100vw ;
    }
 }
 
 


@-webkit-keyframes transitionIN {

    from{
  opacity: 0;
  -webkit-transform: rotateX(-10deg);
          transform: rotateX(-10deg);
  
    }to{
  
  opacity: 1;
  -webkit-transform: rotateX(0);
          transform: rotateX(0);
  }
  
  }
 
 


@keyframes transitionIN {

    from{
  opacity: 0;
  -webkit-transform: rotateX(-10deg);
          transform: rotateX(-10deg);
  
    }to{
  
  opacity: 1;
  -webkit-transform: rotateX(0);
          transform: rotateX(0);
  }
  
  }
  
  
  
  @-webkit-keyframes transitionD {
  
    from{
  opacity: 0;
  -webkit-transform: translateY(-10rem);
          transform: translateY(-10rem);
  
    }to{
  
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  
  }
  
  }
  
  
  
  @keyframes transitionD {
  
    from{
  opacity: 0;
  -webkit-transform: translateY(-10rem);
          transform: translateY(-10rem);
  
    }to{
  
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  
  }
  
  }
  
  
  @-webkit-keyframes transitionZ {
  
    from{
  opacity: 0;
  -webkit-transform: translateX(100rem);
          transform: translateX(100rem);
  
    }to{
  
  opacity: 1;
  -webkit-transform: translateX(100);
          transform: translateX(100);
  
  }
  
  }
  
  
  @keyframes transitionZ {
  
    from{
  opacity: 0;
  -webkit-transform: translateX(100rem);
          transform: translateX(100rem);
  
    }to{
  
  opacity: 1;
  -webkit-transform: translateX(100);
          transform: translateX(100);
  
  }
  
  }


  .GSZ,.GS1{
    width: 120rem;
    height: 51rem;
    opacity: 1;
    background-attachment: fixed;
    background: url("/images/SS.png") no-repeat center center fixed !important;
  
}
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  
}
.GS0{
    min-height: 90vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;    
    -webkit-animation: transitionIN;    
            animation: transitionIN;

}
.block{
    background-color: whitesmoke;
    border-radius: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
margin: 10px;
    align-items: left;
height: 350px;
width: 350px;
border: 2px solid ;
border-radius: 1%;



user-drag: none; 
user-select: none;
-moz-user-select: none;
-webkit-user-drag: none;
-webkit-user-select: none;
-ms-user-select: none;

-webkit-animation: transitionIN 3s;

        animation: transitionIN 3s;

border-color: rgb(255, 175, 2);

    
}

.block:hover{
border-color: black;
border-radius: 20%;
transition: 100ms linear ;
}


#BS{
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}
h4, h1, h2, h3{
    color: black;
    font-family: 'Oswald';
}


@-webkit-keyframes transitionIN {

    from{
  opacity: 0;
  -webkit-transform: rotateX(-10deg);
          transform: rotateX(-10deg);
  
    }to{
  
  opacity: 1;
  -webkit-transform: rotateX(0);
          transform: rotateX(0);
  }
  
  }


@keyframes transitionIN {

    from{
  opacity: 0;
  -webkit-transform: rotateX(-10deg);
          transform: rotateX(-10deg);
  
    }to{
  
  opacity: 1;
  -webkit-transform: rotateX(0);
          transform: rotateX(0);
  }
  
  }
  
  
  
  @-webkit-keyframes transitionD {
  
    from{
  opacity: 0;
  -webkit-transform: translateY(-10rem);
          transform: translateY(-10rem);
  
    }to{
  
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  
  }
  
  }
  
  
  
  @keyframes transitionD {
  
    from{
  opacity: 0;
  -webkit-transform: translateY(-10rem);
          transform: translateY(-10rem);
  
    }to{
  
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  
  }
  
  }
  
  
  @-webkit-keyframes transitionZ {
  
    from{
  opacity: 0;
  -webkit-transform: translateX(100rem);
          transform: translateX(100rem);
  
    }to{
  
  opacity: 1;
  -webkit-transform: translateX(100);
          transform: translateX(100);
  
  }
  
  }
  
  
  @keyframes transitionZ {
  
    from{
  opacity: 0;
  -webkit-transform: translateX(100rem);
          transform: translateX(100rem);
  
    }to{
  
  opacity: 1;
  -webkit-transform: translateX(100);
          transform: translateX(100);
  
  }
  
  }

.form{
display: block;
position: absolute;
left: 35%;
top: 10%;
height: 800px;
}

#slikac{
   background-color: black; 
   position: absolute;
 right:  2%;
 bottom: 25%;
 border-radius: 100%;
 width: 25%;
   float: left;

   outline: none;
   user-select: none;
   user-select: none;
   -moz-user-select: none !important; 
   -webkit-user-drag: none;
   -webkit-user-select: none;
   -ms-user-select: none;
}

.GS2{

     background: none; 
}

.privatnost{

    display: block;
    position: absolute;
    right: 69%;
    width: 30vw;
    height: 30vw;
    bottom: 30%;
    font-family: "Oswald";
    font-size: 1.1%;
    color: black;

    outline: none;
    user-select: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

@media only screen and (max-width: 600px) {


  .form{
    display: block;
    position: absolute;
    left: 0%;
    top: 10%;
    width: 100vw;
    height: 800px;

    
    outline: none;
    user-select: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    }

#slikac{
  display: none;
  background-color: black;
}

.privatnost{

  display: block;
  position: absolute;
  right: 1%;
  width: 100vw;
  height: 30vw;
  bottom: -100%;
  font-family: "Oswald";
  font-size: 1.1%;
  color: black;

  outline: none;
  user-select: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-animation: transitionIN 3s;
          animation: transitionIN 3s;

}
}



@media only screen and (max-width: 600px) {



  .GS1,  #GS1{
      width: 100%;
      
  }


#GSZ, .GSZ{
  background-color: blue;
  width: 100vw;
}




@media only screen and (max-width: 600px) {

 .GS{
     width: 100vw !important;
 }
}
}

@-webkit-keyframes transitionIN {

  from{
opacity: 0;
-webkit-transform: rotateX(-10deg);
        transform: rotateX(-10deg);

  }to{

opacity: 1;
-webkit-transform: rotateX(0);
        transform: rotateX(0);
}

}

@keyframes transitionIN {

  from{
opacity: 0;
-webkit-transform: rotateX(-10deg);
        transform: rotateX(-10deg);

  }to{

opacity: 1;
-webkit-transform: rotateX(0);
        transform: rotateX(0);
}

}



@-webkit-keyframes transitionD {

  from{
opacity: 0;
-webkit-transform: translateY(-10rem);
        transform: translateY(-10rem);

  }to{

opacity: 1;
-webkit-transform: translateY(0);
        transform: translateY(0);

}

}



@keyframes transitionD {

  from{
opacity: 0;
-webkit-transform: translateY(-10rem);
        transform: translateY(-10rem);

  }to{

opacity: 1;
-webkit-transform: translateY(0);
        transform: translateY(0);

}

}


@-webkit-keyframes transitionZ {

  from{
opacity: 0;
-webkit-transform: translateX(100rem);
        transform: translateX(100rem);

  }to{

opacity: 1;
-webkit-transform: translateX(100);
        transform: translateX(100);

}

}


@keyframes transitionZ {

  from{
opacity: 0;
-webkit-transform: translateX(100rem);
        transform: translateX(100rem);

  }to{

opacity: 1;
-webkit-transform: translateX(100);
        transform: translateX(100);

}

}

.fl{
    font-size: 0.9rem;
color: black;
margin-left: 0vw;
}

label{
  color: black;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}

.mz{
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
top: 50%;
left: 50%;


}





form input {
  display: inline-block;
}

#form{
  display: block;
  position: absolute;
  margin-left: 10px;
  width: 70%;
  height: 100%;


  
}



  .lb{
    display: flexbox;
    font-family: cursive;
    margin-left: 1vw;
  }

  .i{

    width: 20%;
    overflow: hidden;
    font-size: 1.042vw;
    padding: 0.2604vw 0;
    margin: 1vw 0;
    border-bottom: 1px solid rgb(255, 175, 2);
    border-top: none;
 
   
 }
 
 .i:hover{
    border-bottom-color: black;
 }


 .i i{

  width: 1.3542vw;
  float: left;
  text-align: center;
}

.i input{
border:  none;
outline: none;
background: none;
color: black;
width: 80%;
font-size: 0.938vw;
float: left;
margin: 0 0.521vw;

}

.i2{

  width: 110%;
  overflow: hidden;
  font-size: 1.042vw;
  padding: 0.2604vw 0;
  margin: 1vw 0;
  border-bottom: 1px solid rgb(255, 175, 2);
  border-top: none;

 
}

.i2:hover{
  border-bottom-color: black;
}


.i2 i{

width: 1.3542vw;
float: left;
text-align: center;
}

.i2 input{
border:  none;
outline: none;
background: none;
color: black;
width: 80%;
font-size: 0.938vw;
float: left;
margin: 0 0.521vw;

}




.select{
  width: 20%;
  overflow: hidden;
  font-size: 1.042vw;
  padding: 0.2604vw 0;
  margin: 1vw 0;
  border-bottom: 1px solid rgb(255, 175, 2);
  border-top: none;

}


#s1{
position: absolute;
top: -4%;
width: 20%;
height: 88%;
left: 30%;
margin-top: 35px;
}

#s2{

  position: absolute;
  left: 60%;
  width: 40%;
  height: 75%;
  top: 3%
}

#submit{
  width: 100%;
background: none;
border: 2px solid rgb(255, 175, 2);
color: black;
padding: 0.2599vw;
font-size: 0.938vw ;
cursor: pointer;
margin: 5px 0;
font-family: "Oswald";
border: 0;
}

#submitd{
  position: absolute;
  left: 140%;
  bottom: -10%;
  border: 2px solid rgb(255, 175, 2);
  width: 20%;
}



@media only screen and (max-width: 600px) {

  #slikac{
    display: none !important;
  }
.rb, #rb{
  display: none !important;
}
  #submit{
    width: 100%;
  background: none;
  border: 2px solid rgb(255, 175, 2);
  color: black;
  padding: 0.2599vw;
  font-size: 15px ;
  cursor: pointer;
  margin: 5px 0;
  font-family: "Oswald";
  border: 0;
  }
  
  #submitd{
    margin-bottom: 50vw;
    right: 5%;
    float: right;
    font-size: 10px;
    bottom: 100%;
    border: 2px solid rgb(255, 175, 2);
    width: 20%;
  }
  

#pa{
  position: absolute;
  font-size: 10px;
  left: 0%;
}


#s1{
position: static;
position: initial;
width: 100%;
}

#S0, #S1{
  position: static;
  position: initial;
  width: 139%;
}

#s2{
  position: static;
  position: initial;
  width: 100%;
}

#submit, #submitd{
  position: static;
  position: initial;
}




.i, .i2{

  width: 100%;
  overflow: hidden;
  font-size: 30000px;
  padding: 0.2604vw 0;
  margin: 1vw 0;
  border-bottom: 1px solid rgb(255, 175, 2);
  border-top: none;

 
}



.i:hover{
  border-bottom-color: black;
}


.i i{

width: 1.3542vw;
float: left;
text-align: center;
}

.i input{
border:  none;
outline: none;
background: none;
color: black;
width: 80%;
font-size: 3.938vw;
float: left;
margin: 0 0.521vw;

}

.i2{

width: 139%;
overflow: hidden;
font-size: 3.042vw;
padding: 0.2604vw 0;
margin: 1vw 0;
border-bottom: 1px solid rgb(255, 175, 2);
border-top: none;


}

.i2:hover{
border-bottom-color: black;
}


.i2 i{

width: 3.3542vw;
float: left;
text-align: center;
}

.i2 input{
border:  none;
outline: none;
background: none;
color: black;
width: 80%;
font-size: 3.938vw;
float: left;
margin: 0 0.521vw;

}




.select{
width: 20%;
overflow: hidden;
font-size: 1.042vw;
padding: 0.2604vw 0;
margin: 1vw 0;
border-bottom: 1px solid rgb(255, 175, 2);
border-top: none;

}

}

h2{
    margin-left: 10.5vw;
    font-family: "Giraso";
    font-size: 1.6rem;
    -webkit-animation: transitionIN 1s;
            animation: transitionIN 1s;
    color: rgb(228, 109, 11);


}

#gr{
    float: left;
    flex-direction: column;
    font-size: 1.6rem;
    bottom: 35%;
    width: 50%;
    color: rgb(228, 109, 11);

   
}

#O1{
    width: 70vh;
}

#O2{
    
    width: 56vh;
    position: absolute;
    bottom: 5%;
    display: inline-block;
   

}

#a1{
    position: absolute;
    left: 40%;
    
    top: 90%;
    
    
    font-family: "Lobster";
    font-size: 1rem;
}

.drugip{
background-color: red;
    display: flex;
    float: left;
}

#p1{
    position: absolute;
    float: left;
    flex-direction: column;

    top: 67%;
}

p{ 
font-family: "Oswald";
    font-size: 1.1rem;
color: black;
margin-left: 1vw;
-webkit-animation: transitionIN 3s;
        animation: transitionIN 3s;


}

.desnon{
    position: absolute;
 left: 70%; 
top: 8%;
color: rgb(228, 109, 11);

}
 




ol{
    position: absolute;
    display: block;
    font-size: 1.1rem;
    font-family: "Oswald";
    color: black;
left: 73%;
top: 14%;
-webkit-animation: transitionIN 3s;
        animation: transitionIN 3s;

}


.GS1{
      
        
    width: 100rem;
    background-color: black;
    height: 50rem;
    opacity: 1;
    background-attachment: fixed;
    background: url(/public/images/crnagora.png) no-repeat center center fixed ;


}

@media only screen and (max-width: 600px) {

.desnon{
    position: absolute;
    color: rgb(228, 109, 11);
  left: 0.1%;
  top: 50%;
  display: block;
  margin-top: 0;
}

#os{
    color: rgb(228, 109, 11);}

    ol{
        position: absolute;
        display: block;
        font-size: 1.1rem;
        font-family: "Oswald";
        color: black;
    left: 1%;
    top: 55%;
    
    }

 

    .GS1{
        background-image: none;

    }

    .GS{
        background-image: none;
    }
  

    #O1{
        display: flex;
        float: left;
        width: 100vw;
        height: 15rem;
        font-size: 100rem;
        font-family: "Oswald";
        color: black;

    }

    #a1{
      display: none;
    }
    
    #O2{
        width: 100vw;
        font-size: 20rem;
       margin-top: 100px;
        top: 10%;
        display: none;

    }

    #gr{
      
margin-top: 22rem;

    color: rgb(228, 109, 11);
    }


.izplav{
    width: 55vh;
}

}


@media only screen and (max-width: 600px) {



    .GS1,  #GS1{
        width: 100%;
        background-image: none !important; 

    }


#GSZ, .GSZ{
    background-color: blue;
    width: 100vw;
}




@media only screen and (max-width: 600px) {

   .GS{
       width: 100vw !important;
   }
}
}

#T{
 position: absolute;
 left: 50%;
 top: 10%;
 color: rgb(228, 109, 11);
}


.midp{
    color: red;
    position: absolute;

    top: 20%;
margin-left: 1px;
display: block;
margin-right: 0px;
border-color: 10px solid black;
width: 50%;
height: 100%;
-webkit-animation: transitionIN 3s;
        animation: transitionIN 3s;

margin-bottom: 0px;

}

@media only screen and (max-width: 414px) {

    #T{
        position: absolute;
        left: 50%;
        top: 10%;
        color: red;
       }
       
       .midp{
        color: red;
        position: center;
        margin-top: 30%;
        width: 100%;
        height: 100%;
    margin-left: 100px;
    margin-bottom:100px ;
    margin-right: 100px;
    border-color: 2px solid red;
    -webkit-animation: transitionIN 3s;
            animation: transitionIN 3s;

    ;
    }
}


@-webkit-keyframes transitionIN {

    from{
  opacity: 0;
  -webkit-transform: rotateX(-10deg);
          transform: rotateX(-10deg);
  
    }to{
  
  opacity: 1;
  -webkit-transform: rotateX(0);
          transform: rotateX(0);
  }
  
  }


@keyframes transitionIN {

    from{
  opacity: 0;
  -webkit-transform: rotateX(-10deg);
          transform: rotateX(-10deg);
  
    }to{
  
  opacity: 1;
  -webkit-transform: rotateX(0);
          transform: rotateX(0);
  }
  
  }
  
  
  
  @-webkit-keyframes transitionD {
  
    from{
  opacity: 0;
  -webkit-transform: translateY(-10rem);
          transform: translateY(-10rem);
  
    }to{
  
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  
  }
  
  }
  
  
  
  @keyframes transitionD {
  
    from{
  opacity: 0;
  -webkit-transform: translateY(-10rem);
          transform: translateY(-10rem);
  
    }to{
  
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  
  }
  
  }
  
  
  @-webkit-keyframes transitionZ {
  
    from{
  opacity: 0;
  -webkit-transform: translateX(100rem);
          transform: translateX(100rem);
  
    }to{
  
  opacity: 1;
  -webkit-transform: translateX(100);
          transform: translateX(100);
  
  }
  
  }
  
  
  @keyframes transitionZ {
  
    from{
  opacity: 0;
  -webkit-transform: translateX(100rem);
          transform: translateX(100rem);
  
    }to{
  
  opacity: 1;
  -webkit-transform: translateX(100);
          transform: translateX(100);
  
  }
  
  }
.PP{
display: block;
position: absolute;
left: 35%;
top: 40%;
align-items: center;
-webkit-animation: transitionIN 1s;
        animation: transitionIN 1s;
}

@-webkit-keyframes transitionIN {

    from{
  opacity: 0;
  -webkit-transform: rotateX(-10deg);
          transform: rotateX(-10deg);
  
    }to{
  
  opacity: 1;
  -webkit-transform: rotateX(0);
          transform: rotateX(0);
  }
  
  }

@keyframes transitionIN {

    from{
  opacity: 0;
  -webkit-transform: rotateX(-10deg);
          transform: rotateX(-10deg);
  
    }to{
  
  opacity: 1;
  -webkit-transform: rotateX(0);
          transform: rotateX(0);
  }
  
  }
  
  
  
  @-webkit-keyframes transitionD {
  
    from{
  opacity: 0;
  -webkit-transform: translateY(-10rem);
          transform: translateY(-10rem);
  
    }to{
  
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  
  }
  
  }
  
  
  
  @keyframes transitionD {
  
    from{
  opacity: 0;
  -webkit-transform: translateY(-10rem);
          transform: translateY(-10rem);
  
    }to{
  
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  
  }
  
  }
  
  
  @-webkit-keyframes transitionZ {
  
    from{
  opacity: 0;
  -webkit-transform: translateX(100rem);
          transform: translateX(100rem);
  
    }to{
  
  opacity: 1;
  -webkit-transform: translateX(100);
          transform: translateX(100);
  
  }
  
  }
  
  
  @keyframes transitionZ {
  
    from{
  opacity: 0;
  -webkit-transform: translateX(100rem);
          transform: translateX(100rem);
  
    }to{
  
  opacity: 1;
  -webkit-transform: translateX(100);
          transform: translateX(100);
  
  }
  
  }

.GS00{
    height:  90vw;
}


.pio{
    
   
    color: red;
    position: absolute;
    top: 10%;
margin-left: 1px;
display: block;
margin-right: 0px;
border-color: 10px solid black;
width: 100%;
height: 170%;
-webkit-animation: transitionIN 3s;
        animation: transitionIN 3s;

margin-bottom: 0px;


}


@media only screen and (max-width: 600px) {

    #T{
        position: absolute;
        left: 50%;
        top: 10%;
        color: red;
       }
       
       .midp{
        color: red;
        position: center;
        margin-top: 30%;
        width: 100%;
        height: 100%;
    margin-left: 100px;
    margin-bottom:100px ;
    margin-right: 100px;
    border-color: 2px solid red;
    -webkit-animation: transitionIN 3s;
            animation: transitionIN 3s;

    ;
    }
}

