#T{
 position: absolute;
 left: 50%;
 top: 10%;
 color: rgb(228, 109, 11);
}


.midp{
    color: red;
    position: absolute;

    top: 20%;
margin-left: 1px;
display: block;
margin-right: 0px;
border-color: 10px solid black;
width: 50%;
height: 100%;
animation: transitionIN 3s;

margin-bottom: 0px;

}

@media only screen and (max-width: 414px) {

    #T{
        position: absolute;
        left: 50%;
        top: 10%;
        color: red;
       }
       
       .midp{
        color: red;
        position: center;
        margin-top: 30%;
        width: 100%;
        height: 100%;
    margin-left: 100px;
    margin-bottom:100px ;
    margin-right: 100px;
    border-color: 2px solid red;
    animation: transitionIN 3s;

    ;
    }
}


@keyframes transitionIN {

    from{
  opacity: 0;
  transform: rotateX(-10deg);
  
    }to{
  
  opacity: 1;
  transform: rotateX(0);
  }
  
  }
  
  
  
  @keyframes transitionD {
  
    from{
  opacity: 0;
  transform: translateY(-10rem);
  
    }to{
  
  opacity: 1;
  transform: translateY(0);
  
  }
  
  }
  
  
  @keyframes transitionZ {
  
    from{
  opacity: 0;
  transform: translateX(100rem);
  
    }to{
  
  opacity: 1;
  transform: translateX(100);
  
  }
  
  }